import React from 'react';
import styles from './Advertise.module.css';
import axios from "axios";

class Advertise extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            fullname :'',
            email :'',
            phone : '',
            message: ''
        };

        this.handleChange = this.change.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    change = e => {

        this.setState({[e.target.name]: e.target.value});

    };

    resetFields () {
        this.setState({
            fullname: '',
            email: '',
            phone: '',
            message: '',
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        let self = this;
        axios.post('http://talco.co/advertise.php', {
            "fullname": this.state.fullname,
            "email": this.state.email,
            "phone": this.state.phone,
            "message": this.state.message
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(function (response) {
                console.log(response);
                self.resetFields();
                alert ("הודעתך נשלחה בהצלחה :)");

            })
            .catch(function (error) {
                console.log(error);
                alert ("שגיאה בשליחת ההודעה :(");
            });
    }

    render() {
        return (

            <div>
                <img src="./instagram_img.png"  alt="image"  className={styles.Image}/>
                <form onSubmit={this.handleSubmit}>
                    <label>שם : </label>
                    <input className="form-control" type="text" name="fullname" value={this.state.fullname} onChange={e => this.change(e)} />
                    <label>מייל : </label>
                    <input className="form-control" type="text" name="email" value={this.state.email} onChange={e => this.change(e)} />
                    <label>טלפון : </label>
                    <input  className="form-control" type="text" name="phone" value={this.state.phone} onChange={e => this.change(e)} />

                    <label>תוכן ההודעה : </label>
                    <textarea  className="form-control" name="message" value={this.state.message} onChange={e => this.change(e)} />

                    <input className="form-control btn btn btn-success"  type="submit" value="שלח/י" />
                </form>
            </div>
        );
    }
}
export default Advertise;
