import React from 'react';
import styles from './ShufersalShirt.module.css';
import axios from "axios";

class ShufersalShirt extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            fullname :'',
            phone : '',
            size : 'xs',
            shirt_fit : 'men',
            message: ''
        };

        this.handleChange = this.change.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    change = e => {

        this.setState({[e.target.name]: e.target.value});

    };

    resetFields () {
        this.setState({
            fullname: '',
            size: 'xs',
            shirt_fit: 'men',
            phone: '',
            message: '',
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        let self = this;
        axios.post('http://talco.co/shufersal-shirt.php', {
            "fullname": this.state.fullname,
            "size": this.state.size,
            "shirt_fit": this.state.shirt_fit,
            "phone": this.state.phone,
            "message": this.state.message
        }, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(function (response) {
                console.log(response);
                self.resetFields();
                alert ("הודעתך נשלחה בהצלחה :)");

            })
            .catch(function (error) {
                console.log(error);
                alert ("שגיאה בשליחת ההודעה :(");
            });
    }

    render() {
        return (

            <div>
                <img src="./shufersal-shirt-front.png"  alt="image"  className={styles.Image}/>
                <img src="./shufersal-shirt-back.png"  alt="image"  className={styles.Image}/>
                <p>100 אחוז כותנה. מחיר : 400 שקלים</p>
                <form onSubmit={this.handleSubmit}>
                    <label>שם : </label>
                    <input className="form-control" type="text" name="fullname" value={this.state.fullname} onChange={e => this.change(e)} />
                    <label>מידה : </label>
                    <select className="form-control" type="text" name="size" value={this.state.size} onChange={e => this.change(e)}>
                        <option value="xs">XS</option>
                        <option value="s">S</option>
                        <option value="m">M</option>
                        <option value="l">L</option>
                        <option value="xl">XL</option>
                        <option value="xxl">XXL</option>
                    </select>
                    <label>גזרה : </label>
                    <select className="form-control" type="text" name="shirt_fit" value={this.state.shirt_fit} onChange={e => this.change(e)}>
                        <option value="men">גברים</option>
                        <option value="women">נשים</option>
                        <option value="gender-fluid">ג'נדר פלואיד</option>
                    </select>
                    <label>טלפון : </label>
                    <input  className="form-control" type="text" name="phone" value={this.state.phone} onChange={e => this.change(e)} />

                    <label>תוכן ההודעה : </label>
                    <textarea  className="form-control" name="message" value={this.state.message} onChange={e => this.change(e)} />

                    <input className="form-control btn btn btn-success"  type="submit" value="שלח/י" />
                </form>
            </div>
        );
    }
}
export default ShufersalShirt;
