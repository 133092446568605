import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import styles from "./App.css";
import About from "./components/About/About";
import Home from "./components/Home/Home";
import React from "react";
import Footer from "./components/Footer/Footer";
import Links from "./components/Links/Links";
import Advertise from "./components/Advertise/Advertise";
import ShufersalShirt from "./components/ShufersalShirt/ShufersalShirt";


function App() {
  return (
      <div className="App">
        <div className="content">
          <Router>
            <header className="App-header">
              <Link to="/" className="App-link">hOME</Link>
              <Link to="/about" className="App-link">aBOUT</Link>
              <Link to="/links" className="App-link">lINKS</Link>
            </header>
            <Switch>
              <Route path="/advertise">
                <Advertise />
              </Route>
              <Route path="/shufersal-shirt">
                <ShufersalShirt />
              </Route>
              <Route path="/links">
                <Links />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Router>
        </div>
        <Footer/>
      </div>
  );
}

export default App;
