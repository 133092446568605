import React from 'react';
import PropTypes from 'prop-types';
import styles from './About.module.css';

const About = () => (
  <div className={styles.About} data-testid="About">
    My name is Tal, and this is my website. <br/>
      When I first started Talcorp in 2006 it was in my parents basement. <br/>
      Now Talcorp is a major software company with over 600 employees and more than 50 active projects.


  </div>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
