import React from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.css';

const Home = () => (
    <div className={styles.Home} data-testid="Home">
        <p>
            Welcome to my website.

        </p>
        <br/><br/>

        <img src="./nyan.gif"  alt="nyan" className={styles.MainImage} />
        <br/>

    </div>
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
