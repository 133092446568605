import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
    <div className={styles.Footer} data-testid="Footer">
        <a target="_blank" href="https://www.facebook.com/tal.kohen" className={styles.FooterIcon}><FontAwesomeIcon icon={faFacebook} /></a>
        <a target="_blank" href="https://www.instagram.com/talkohen/" className={styles.FooterIcon}><FontAwesomeIcon icon={faInstagram}/></a>
        <br/>
        © All Rights Reserved Talco 2021
    </div>

);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
