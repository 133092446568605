import React from 'react';
import PropTypes from 'prop-types';
import styles from './Links.module.css';

const Links = () => (
  <div className={styles.Links} data-testid="Links">
      <h2>Apps</h2>
      <ul>
          <li> <a target="_blank" href="https://play.google.com/store/apps/details?id=talco.co.talco">Talco</a> </li>
          <li> <a target="_blank" href="https://play.google.com/store/apps/details?id=talco.co.newyearcountdown">New years countdown</a> </li>
          <li> <a target="_blank" href="https://play.google.com/store/apps/details?id=co.talco.emergencycaller">Emergency Caller</a> </li>
          <li> <a target="_blank" href="https://play.google.com/store/apps/details?id=co.talco.stickerz">Stickerz</a> </li>
      </ul>

      <h2>Websites</h2>
      <ul>
        <li> <a target="_blank" href="https://noolart.com/">Noolart</a> </li>
          <li> <a target="_blank" href="https://jackieo.site/">JackieO</a> </li>
          <li> <a target="_blank" href="https://lady-godiva.site/">Lady godiva</a> </li>

      </ul>

      <h2>Stupid Crap</h2>
      <ul>
          <li> <a href="/advertise">Advertise on Instagram</a> </li>
          <li> <a href="/shufersal-shirt">Buy a shufersal shirt</a> </li>
          <li> Shavout filter for <a href="https://www.facebook.com/fbcameraeffects/tryit/2421362428154423/">Facebook</a> / <a href="https://www.instagram.com/ar/2421362428154423/">Instagram</a></li>
          <li> Yemen filter for <a href="https://www.facebook.com/fbcameraeffects/tryit/651540955442507/">Facebook</a> / <a href="https://www.instagram.com/ar/651540955442507/">Instagram</a></li>
      </ul>
  </div>
);

Links.propTypes = {};

Links.defaultProps = {};

export default Links;
